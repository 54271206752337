.re-fil-type {
    width: 100vw;
    overflow-x: scroll !important;
}
.re-fil-type::-webkit-scrollbar {
    height: 0px !important;
    width: 8px !important;
    border: 1px solid #fff !important;
}

.re-fil-type ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
}

.re-fil-type ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reStarFilterType {
    min-width: 45px !important;
    padding: 2px 10px !important;
    font-size: 12px !important;
    border: 1px solid #ccc !important;
    color: #000 !important;
}

.re-fil-type ul li.active button {
    background-color: rgb(0, 171, 85);
    color: #fff !important;
    border: 1px solid rgb(0, 171, 85) !important;
}