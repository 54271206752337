.s-tabs {
    padding: 0;
    margin: 0;
    margin-right: 30px;
    max-width: 360px;
    min-width: 48px;
    font-weight: 600;
    min-height: 48px;
}

.s-divider {
    border-width: 0px 0px thin;
    border-color: rgba(145, 158, 171, 0.24);
    border-style: dashed;
    margin: 10px 20px;
}

.tab-setting {
    padding: 24px;
}

/*QR settings*/
.qr-setting-wrap {
    display: flex;
    column-gap: 20px;
}

.qr-setting-left {
    width: 50%;
}

.qr-setting-left-wrap {
    padding: 20px;
}

.qr-setting-logo-wrap {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.qr-setting-logo {
    height: 90px;
    width: 90px;
    border-radius: 50px;
    background: aqua;
}

.qr-setting-logo img {
    border-radius: 50px;
    height: 100%;
    width: 100%;
}

.qr-card-main-wrap {
    margin: 0 auto;
    height: 720px;
    width: 480px;
}

.qr-setting-card {
    overflow-x: auto;
}

.qr-card-prev {
    height: 720px;
    width: 480px;
    padding: 30px;
    border-radius: 10px;
}

.qr-setting-name-wrap {
    display: flex;
    flex-direction: column;
}

.qr-setting-name {
    font-size: 20px;
    font-weight: 700;
}

.qr-setting-name-area {
    font-size: 15px;
}

.qr-scan-for-menu {
    text-align: center;
    margin: 30px;
    font-size: 18px;
    font-weight: 700;
}

.qr-setting-qr {
    text-align: center;
}

.website-qr {
    text-align: center;
    font-size: 14px;
    padding-top: 8px;
}

.qr-setting-addr {
    padding: 30px;
    padding-bottom: 0;
    text-align: center;
    font-size: 16px;
    color: #000;
    padding-top: 12px;
}

.qr-setting-mobile {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.qr-setting-power {
    text-align: center;
}

.qr-txt-color {
    color: #fff;
}

.qr-settings-btn-wrap {
    display: flex;
    gap: 12px
}

.qr-setting-label-txt {
    font-size: 14px;
    margin-bottom: 12px;
}

.twitter-picker span:nth-child(20) div {
    border: 1px solid #795548;
}

pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

/**Theme configuration**/
.theme-btn-wrap {
    display: flex;
    gap: 15px;
    max-width: 370px;
}

@media (max-width: 900px) {
    .qr-setting-wrap {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .qr-setting-left {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .tab-setting {
        padding: 0;
        padding-top: 24px;
    }
}