.user-booking-wrap {
    margin-top: 100px;
}

.user-booking-container {
    max-width: 700px;
    margin: 0 auto;
}

.user-booking-one {
    padding: 20px;
}

.user-booking-title {
    font-size: 14px;
    color: #637381;
}

.user-booking-one-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 10px;
}

.user-booking-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.bold-user-booking {
    font-weight: 700;
    font-size: 14px;
}

.user-booking-one-body {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.user-booking-one-body-d {
    margin-top: 10px;
}

.f-14 {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.f-13 {
    font-size: 13px;
}

.f-bold {
    font-weight: 700;
}

.user-booking-status {
    background: rgb(0, 171, 85);
    color: #fff;
    padding: 2px 4px;
    border-radius: 5px;
}

.user-booking-heading {
    padding: 15px 20px;
    font-weight: 600;
}

.booking-div {
    border-width: 0px 0px thin;
    border-color: rgba(145, 158, 171, 0.24);
    border-style: dashed;
}

@media (max-width: 500px) {

    .user-booking-one-body {
        flex-direction: column;
    }
}

@media (max-width: 440px) {

    .user-booking-one-head,
    .user-booking-one-body {
        flex-direction: column;
        align-items: flex-start;
    }
}