.about-banner {
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    background-image: url('../../../public/assets/images/about-us.jpg');
    position: relative;
}

.about-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
}

.about-banner-text {
    position: absolute;
    top: calc(50% + 50px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: #fff;
    font-weight: 600;
}

.about-txt {
    margin-top: 30px;
}

@media (max-width: 839px) {
    .about-banner {
        height: 320px;
    }

    .about-banner-text {
        top: calc(50% + 30px);
    }

    .about-txt {
        font-size: 14px;
    }

}

@media (max-width: 550px) {
    .about-banner {
        height: 250px;
    }

    .about-banner-text {
        top: calc(50% + 30px);
    }
}