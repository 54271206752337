.u-image-wrap {
    width: 155px;
    height: 155px;
    margin: auto;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    border-radius: 15%;
    align-items: center;
    position: relative;
    justify-content: center;
    border: 1px dashed rgba(145, 158, 171, 0.32);
}

.u-image {
    width: 136px;
    height: 136px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(145, 158, 171);
    background-color: rgb(244, 246, 248);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15%;
}

.p-container {
    display: grid;
    padding: 15px;
    border-radius: 16px;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
}

.p-wrap {
    height: 220px;
    width: 100%;
    border-radius: 16px;
    position: relative;
}

.p-wrap img {
    height: 100%;
    width: 100%;
    border-radius: 16px;
}

.p-images {
    padding: 20px;
}

.p-images ul {
    padding: 0;
    margin: 0;
}

.p-images ul li {
    position: relative;
    list-style: none;
    width: 120px;
    height: 120px;
    background: aliceblue;
    display: inline-block;
    margin: 5px;
    border-radius: 16px;
}

.p-images ul li img {
    max-width: 100%;
    height: 100%;
    border-radius: 16px;
}

@media (max-width: 950px) {
    .p-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 750px) {
    .p-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 515px) {
    .p-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 340px) {
    .p-images ul li {
        width: 114px;
        height: 114px;
    }
}