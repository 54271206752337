.o-card {
    display: grid;
    padding: 30px 15px;
    border-radius: 16px;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);

}

.o-card-wrap {
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    border: 1px solid rgba(145, 158, 171, 0.24);
    background-image: none;
    padding: 5px 5px 20px 15px;
    width: 100%;
    position: relative;
}

.o-card-content {
    display: flex;
    column-gap: 15px;
    align-items: center;
}

.o-offer-heading {
    font-size: 14px;
    font-weight: 500;
}

.o-offer-date {
    font-size: 13px;
}

.o-status-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.o-status-box {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background: rgb(0, 171, 85);
    background: rgb(255, 72, 66);
}

.o-status-text {
    font-size: 14px;
    font-weight: 600;
    color: rgb(0, 171, 85);
    color: rgb(255, 72, 66);
}

@media (max-width: 920px) {
    .o-card {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 550px) {
    .o-card {
        grid-template-columns: repeat(1, 1fr);
    }
}