.privacy-page {
    margin-top: 100px;
}

.privacy-container {
    max-width: 800px;
    margin: 0 auto;
}

.privacy-heading {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}
@media (max-width: 839px) {
    .privacy-container {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 100%;
    }

    .privacy-container p {
        font-size: 15px;
        line-height: 22px;
    }
}