.store-social-share {
    margin-top: 15px;
    margin-bottom: 15px;
}

div[class^="makeStyles-container-"] {
    padding-top: 10px;
    padding-bottom: 10px;
}

div[class^="makeStyles-copyContainer-"] {
    border: 1px dashed rgba(0, 0, 0, 0.3);
    background: rgb(214, 245, 226);
}

div[class^="makeStyles-copyUrl-"] {
    color: #000;
}