.v1-store-items-d-page {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}

.v1-store-items-d-header {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 1;
    max-width: 800px;
    background: #fff;
    height: 80px;
}

.v1-store-items-d-header p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

.v1-store-items-d-body {
    padding-top: 90px;
}

.v1-store-items-d-img-bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 16px;
    width: 400px;
    height: 400px;
    cursor: zoom-in;
    position: relative;
    margin: 0px auto;
}

.v1-store-items-d-desc {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 20px;
}

.v1-store-item-d-name {
    font-size: 18px;
    font-weight: 500;
}

.v1-store-items-d-price {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.v1-store-items-d-actual {
    font-size: 14px;
}

.v1-store-items-d-discounted {
    font-size: 15px;
    font-weight: 600;
}

.v1-store-item-d-wrap {
    display: flex;
    gap: 25px
}

.item-d-variants {
    margin-top: 15px;
}

.item-d-variant-name {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
}

.item-d-variant-wrap {
    display: flex;
    gap: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    flex-flow: wrap;
}

.item-d-variant-item {
    border: 1px solid #bfc0c6;
    padding: 8px 20px;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    border-radius: 50px;

}

pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.list-item-brand-d {
    font-size: 12px;
    background-color: #8BC34A;
    color: #FFFFFF;
    border-radius: 20px;
    padding: 1px 10px;
    display: inline-block;
}

@media (max-width: 880px) {
    .offer-page-wrap {
        display: flex;
        flex-direction: column;
    }

    .v1-store-item-d-wrap {
        flex-direction: column;
    }


    .v1-store-items-d-page {
        max-width: 100%;
    }

    .v1-store-items-d-header {
        height: 60px;
        max-width: 100%;
        padding: 20px 20px;
    }

    .v1-store-items-d-body {
        padding: 20px;
        padding-top: 90px;
    }
}

@media (max-width: 570px) {
    .v1-store-items-d-img-bg {
        width: 100%;
        height: 380px;
    }

    .v1-store-items-d-header {
        padding: 20px 5px;
    }
}

@media (max-width: 470px) {
    .v1-store-items-d-img-bg {
        height: 350px;
    }
}

@media (max-width: 400px) {
    .v1-store-items-d-img-bg {
        height: 275px;
    }
}