.page-header {
    background-color: #fff;
    position: fixed;
    top: 0;
    transition: box-shadow .2s ease-in-out;
    width: 100%;
    z-index: 3;
    height: 64px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
}

.page-header-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 25px;
    height: 100%;
    flex-wrap: wrap;
}

.page-header .profile-icon {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    ;
    background: aliceblue;
}

.h-right-wrap ul {
    padding: 0;
    margin: 0;
    display: flex;
    column-gap: 18px;
    align-items: center;
    flex-wrap: wrap;
}

.h-right-wrap ul li {
    list-style: none;
}

.h-right-wrap ul li a {
    text-decoration: none;
    color: #000;
    font-size: 15px;
}

@media (max-width: 500px) {
    .page-header-content {
        padding: 8px 10px;
    }
}

@media (max-width: 450px) {
    .header-book-demo-sm {
        display: none;
    }
}

@media (max-width: 350px) {
    .header-contact-sm {
        display: none;
    }
}