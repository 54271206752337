.contact-banner {
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    background-image: url('../../../public/assets/images/contact-us.jpg');
    position: relative;
}

.contact-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
}

.contact-banner-text {
    position: absolute;
    top: calc(50% + 50px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: #fff;
    font-weight: 600;
}

.contact-card {
    display: flex;
    flex-direction: row;
    padding: 30px;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
}

.contact-us-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
}

.contact-info {
    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

@media (max-width: 839px) {
    .contact-banner {
        height: 320px;
    }

    .contact-banner-text {
        top: calc(50% + 30px);
    }

    .contact-info {
        width: 45%;
    }
}

@media (max-width: 700px) {
    .contact-card {
        flex-direction: column-reverse;
    }

    .contact-info,
    .contact-us-form {
        width: 100%;
    }
}

@media (max-width: 550px) {
    .contact-banner {
        height: 250px;
    }

    .contact-banner-text {
        top: calc(50% + 30px);
    }
}