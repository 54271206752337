a {
    text-decoration: none;
    color: #000;
}

.home-page {
    margin-top: 100px;
}

.h-header-banner {
    background-image: url("../../../public/assets/images/scan.png");
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    width: 300px;
    cursor: pointer;
    position: relative;
}

.h-banner-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px;
}

.h-banner-title {
    font-size: 50px;
    font-weight: 700;
}

.h-banner-action-btns {
    display: flex;
    column-gap: 10px;
    margin-top: 20px;
}

.h-features-ico-wrap {
    color: #00ab55;
    width: 60px;
    height: 60px;
    background: rgba(0, 171, 85, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.h-features-ico {
    width: 25px;
    height: 25px;
}

.h-features-info-content {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(3, 1fr);
}

.h-features-info {
    background: #f7f9fc;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.h-features-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
}

.h-features-title {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: 700;
}

.h-features-desc {
    font-size: 15px;
}

.home-get-started-title {
    font-weight: 700;
    font-size: 40px;
}

@media (max-width: 800px) {
    .home-page {
        margin-top: 65px;
    }

    .h-banner-wrap {
        flex-direction: column;
    }

    .h-banner-title {
        font-size: 40px;
    }

    .h-header-banner {
        margin-top: 50px;
        height: 500px;
        width: 500px;
    }

    .h-features-info-content {
        grid-template-columns: repeat(2, 1fr);
    }

    .h-banner-wrap {
        padding: 15px;
    }
}

@media (max-width: 550px) {
    .h-features-info-content {
        display: flex;
        flex-direction: column;
    }

    .h-banner-title {
        font-size: 25px;
    }

    .home-get-started-title {
        font-size: 35px;
    }
}

@media (max-width: 500px) {
    .h-header-banner {
        height: 300px;
        width: 300px;
    }
}