.list-item-list {
    display: flex;
    gap: 10px;
}

.list-item-images-bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 16px;
    width: 150px;
    height: 150px;
    cursor: zoom-in;
    position: relative;
    margin: 0px auto;
}

.list-item-body {
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2px;
}

.list-item-name {
    font-size: 18px;
    font-weight: 600;
}

.list-item-unit {
    font-size: 12px;
    font-weight: 400;
    color: #000;
}

.list-item-desc {
    font-size: 14px;
    font-weight: 500;
}

.list-item-price-wrap {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.list-item-price {
    display: flex;
    gap: 10px;
}

.list-item-price-actual {
    font-size: 14px;
}

.list-item-price-discounted {
    font-size: 15px;
    font-weight: 600;
}
