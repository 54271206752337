.v1-store-page {
    margin-top: 100px;
}

.v1-store-container {
    display: grid;
    grid-template-columns: 240px auto 260px;
    column-gap: 20px;
}

@media (max-width: 839px) {
    .v1-store-container {
        grid-template-columns: auto;
        column-gap: 0px;
        margin-bottom: 20px;
    }

    .v1-store-sidemenu,
    .v1-store-rightmenu,
    .store-header-md,
    .store-footer-md {
        display: none;
    }

    .v1-store-page {
        margin-top: 50px;
    }

    .v1-store-container-wrap {
        padding-right: 0;
        padding-left: 0;
    }

}