:root {
  --white: #fff;
}

body {
  font-family: 'Inter', sans-serif;
}

.SnackbarContent-root {
  flex-wrap: nowrap;
}

.SnackbarItem-action {
  padding-left: 0;
}