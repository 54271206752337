.store-header-sm-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 1;
    height: 50px;
}

.store-header-sm-content {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.store-header-sm-left,
.store-header-sm-right {
    display: flex;
    gap: 10px;
    align-items: center;
}

.store-header-sm-name {
    font-size: 15px;
    font-weight: 700;
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 500px) {
    .store-header-sm-name {
        width: 190px;
    }
}

@media (max-width: 475px) {
    .store-header-sm-name {
        width: 250px;
    }
}

@media (max-width: 450px) {
    .store-header-sm-name {
        width: 230px;
    }
}

@media (max-width: 400px) {
    .store-header-sm-name {
        width: 210px;
    }
}


@media (max-width: 375px) {
    .store-header-sm-name {
        width: 190px;
    }
}

@media (max-width: 360px) {
    .store-header-sm-name {
        width: 170px;
    }
}

@media (max-width: 335px) {
    .store-header-sm-name {
        width: 160px;
    }
}


@media (max-width: 320px) {
    .store-header-sm-name {
        width: 140px;
    }
}