.not-found-wrap {
    max-width: 1000px;
    margin: 0 auto;
}

.not-found-header {
    height: 60px;
    border-radius: 5px;
}

.not-need-help {
    padding: 16px;
}

.not-need-help-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    justify-content: end;
}

.not-found-body {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 40px;
    margin: auto;
    max-width: 400px;
    text-align: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.not-found-body .title {
    font-size: 2rem;
    margin: 0px 0px 16px;
    font-weight: 700;
    line-height: 1.5;
}

.not-found-img {
    height: 350px;
    width: auto;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .not-found-body {
        padding: 10px;
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .not-found-body .title {
        font-size: 1.5rem;
    }
}