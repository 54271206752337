
.bottom-sheet-wrap {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: none;
}

.bottom-sheet-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    position: relative;
}

.bottom-sheet-inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    background-color: rgba(255, 255, 255, 1.00);
    max-height: 100%;
    position: fixed;
    width: 100%;
    bottom: 0;
    min-height: calc(100px);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    overflow-y: auto;
    transform: translateY(110px);
    transition: transform 0.3s ease 0s;
}

.bottom-sheet-wrap-active {
    display: block;
}

.bottom-sheet-wrap-active .bottom-sheet-inner {
    transform: translateY(0)
}
