.item-add-card {
    display: grid;
    grid-template-columns: 390px auto;
    column-gap: 20px;
}

.c-image-wrap {
    margin: auto;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    border-radius: 15%;
    align-items: center;
    position: relative;
    justify-content: center;
    border: 1px dashed rgba(145, 158, 171, 0.32);
}

.c-image {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(145, 158, 171);
    background-color: rgb(244, 246, 248);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15%;
}

.c-image-caption {
    margin: 16px auto 0px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-weight: 400;
    color: rgb(99, 115, 129);
    display: block;
    text-align: center;
}

.c-item-image-wrap {
    width: 130px;
    height: 130px;
}

.c-item-image {
    width: 110px;
    height: 110px;
}

.item-images ul {
    padding: 0;
    margin: 0;
}

.item-images ul li {
    position: relative;
    list-style: none;
    width: 102px;
    height: 102px;
    background: aliceblue;
    display: inline-block;
    margin-right: 8px;
    border-radius: 16px;
}

.item-images ul li img {
    max-width: 100%;
    height: 100%;
    border-radius: 16px;
}

.item-images-d {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: rgba(0, 0, 0, .5);
    padding: 3px 4px 0px 4px;
    border-radius: 7px;
    color: #FF4842;
    cursor: pointer;
}

.item-add-card-wrap {
    padding: 30px;
}

/*
Item variant
*/

.item-variant-wrapper {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
}

.item-meta {
    width: 300px;
}

.item-variant-data {
    width: calc(100% - 300px);
    overflow: auto;
}

.item-table-header-wrapper {
    background-color: #f2f2f2;
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1 1;
    border-radius: 4px;
    z-index: 1;
    cursor: default;
}

.item-variant-wrapper .item-meta>.item-table-header-wrapper {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.item-variant-wrapper .item-variant-data>.item-table-header-wrapper {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.item-variant-data>.item-table-header-wrapper {
    width: -webkit-max-content;
    width: max-content;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    justify-content: flex-start;
}

.item-table-header {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #4d4d4d;
}

.item-variant-wrapper .item-variant-data .item-table-header {
    margin-right: 24px;
}

.item-table-data-row {
    padding-bottom: 8px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    flex: 1 1;
}

.item-variants-table-meta-block {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.item-meta .item-table-data-row {
    padding-top: 10px;
}

.item-choose-variant-image {
    height: 55px;
    cursor: pointer;
}

.item-variant-text {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;
    word-break: break-word;
}

.item-table-data-row>* {
    margin-right: 24px;
    justify-content: start;
}

.item-col-price,
.item-col-discounted-price,
.item-col-qty,
.item-col-unit {
    min-width: 125px;
    max-width: 125px;
}

.item-col-sku {
    min-width: 175px;
    max-width: 175px;
}

.item-col-unit {
    display: flex;
    gap: 10px;
    min-width: 190px;
    max-width: 190px;
}

.item-col-action {
    min-width: 20px;
    max-width: 20px;
}

.item-col-unit-text {}

.item-table-data-container {
    padding: 10px 16px;
}

/*Variant modal*/
.add-variant-wrapper {
    width: 100%;
    margin-bottom: 24px;
    margin-top: 24px;
}

.add-variant-wrapper {
    display: flex;
    gap: 10px;
}

.variant-option-group {
    width: 174px;
    margin-right: 24px;
}

.variant-form-group {
    width: calc(100% - 200px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.variant-form-control {
    width: calc(100% - 60px);
}

.variant-action-icon {
    width: 50px;
}

.variant-btn-wrap {
    margin: 20px;
    margin-top: 30px;
    text-align: center;
}

.item-form-color-select {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    width: 100%;
    color: #1a181e;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}

.item-color-popover {
    position: relative;
    padding: 5px 8px;
    color: #999;
}

.item-choose-img {
    width: 64px;
    height: 64px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(145, 158, 171);
    background-color: rgb(244, 246, 248);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15%;
}

.item-images-variant {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    margin-right: -24px;
}

.item-uploaded-images {
    min-width: 108px;
    position: relative;
    display: inline-flex;
}

.item-uploaded-images img {
    width: 108px;
    height: 108px;
    border-radius: 4px;
    object-fit: contain;
    border: 1px solid #e6e6e6;
}

.select-images-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 16px;
}

.uploaded-img-overlay-selected {
    width: 20px;
    height: 20px;
    background-color: #146eb4;
    border-radius: 10px;
    border: solid 1px #fff;
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cur-p {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.white-color {
    color: #fff;
    position: relative;
}

.select-img-btn {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 10px;
    margin-top: 20px;
    max-width: 250px;
    flex: '1 0 auto'
}

.item-uploaded-image {
    position: relative;
    display: inline-flex;
    width: 64px;
    height: 64px;
}

.item-uploaded-image img {
    width: 100%;
    height: 100%;
}

.item-uploaded-image-overlay {
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.item-uploaded-overlay-close-btn,
.item-uploaded-overlay-close-btn:hover {
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
    padding: 2px;
}

.uploaded-images-count {
    color: #fff;
    position: absolute;
    top: 25px;
    left: 28px;
    font-size: 12px;
}

.item-selection-tick {
    position: absolute;
    top: -12px;
    right: -22px;
}

/* New */
.new-item-variant-body {
    display: flex;
    justify-content: left;
    margin: 20px;
    margin-top: 0;
    gap: 10px;
}

.new-item-img {
    width: 64px;
    height: 64px;
    color: rgb(145, 158, 171);
    background-color: rgb(244, 246, 248);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15%;
    position: relative;
}

.new-item-choose-img {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
}

.new-item-img img {
    height: 100%;
    width: 100%;
    border-radius: 15%;
}

.item-data-v1 {
    display: flex;
    margin-bottom: 20px;
    gap: 20px;
}

.item-variant-meta-new {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
}

.item-variant-header-new {
    padding: 10px 10px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 839px) {
    .item-add-card {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    .item-add-card-wrap {
        padding: 20px;
    }
}

@media (max-width: 800px) {
    .item-variant-meta-new {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 500px) {
    .add-variant-wrapper {
        flex-direction: column;
    }

    .variant-form-group {
        width: calc(100% - 15px);
    }

    .variant-option-group {
        width: 77%;
    }

    .variant-form-control {
        width: calc(100% - 55px);
    }

    .item-unit-txtfield {
        width: 120px;
    }
}