.category-add-card {
    display: grid;
    grid-template-columns: 350px auto;
    column-gap: 20px;
}

.c-image-wrap {
    width: 155px;
    height: 155px;
    margin: auto;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    border-radius: 15%;
    align-items: center;
    position: relative;
    justify-content: center;
    border: 1px dashed rgba(145, 158, 171, 0.32);
}

.c-image {
    width: 136px;
    height: 136px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(145, 158, 171);
    background-color: rgb(244, 246, 248);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15%;
}

.c-image-caption {
    margin: 16px auto 0px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-weight: 400;
    color: rgb(99, 115, 129);
    display: block;
    text-align: center;
}

.close-image-ico {
    position: absolute;
    color: rgba(255, 255, 255, 0.72);
    background-color: rgba(22, 28, 36, 0.48);
    top: 2px;
    right: 2px;
}

.category-add-card-wrap {
    padding: 30px;
}

@media (max-width: 800px) {
    .category-add-card {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    .category-add-card-wrap {
        padding: 20px;
    }
}