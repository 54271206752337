.analytics-card-wrap {
    flex-flow: wrap;
    gap: 12px;
}

.f-posted-date {
    background: #ebf8f2;
    font-size: 12px;
    margin-top: 5px;
    border-radius: 15px;
    display: inline-block;
    padding: 4px 13px;
    font-weight: 500;
}

.a-card {
    padding: 16px 30px;
    min-width: 180px;
    margin-left: 0 !important;
}

.a-card-heading {
    font-size: 13px;
    font-weight: 500;
}

.a-card-body {
    margin-top: 10px;
}

.a-card-count {
    font-size: 25px;
    font-weight: 700;
}

@media (max-width: 500px) {
    .a-card {
        width: 100%;
    }
}