.v1-portal-dashboard {
    display: grid;
    grid-template-columns: auto 300px;
    column-gap: 20px;
}

.page-dashboard .qrwrap {
    background-color: rgba(255, 255, 255, 1.00);
    border-radius: 16px;
    border-color: rgb(239, 243, 244);
    border-width: 1px;
    border-style: solid;
}

.page-dashboard .qrTitle {
    padding: 15px 15px 10px 15px;
    font-size: 16px;
    font-weight: 500;
}

.page-dashboard .qrwrapInner {
    margin: 0 auto;
    width: 220px;
    padding: 10px;
}

.page-dashboard .qrAction {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin: 5px 15px 15px 15px;
}

.d-b-username {
    display: flex;
    column-gap: 20px;
    margin-bottom: 25px;
}

.d-b-username-container {
    padding: 25px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.d-b-main-site {
    font-size: 16px;
    font-weight: 700;
    margin-top: -45px;
}

@media (max-width: 750px) {
    .v1-portal-dashboard {
        grid-template-columns: auto 285px;
    }

    .v1-dashboard-store-url {
        font-size: 14px;
    }
}

@media (max-width: 660px) {
    .v1-portal-dashboard {
        display: flex;
        gap: 20px;
    }

    .d-b-username-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .d-b-main-site {
        margin-top: 0;
    }
}