.book-demo-page {
    margin-top: 100px;
}

.book-demo-container {
    max-width: 600px;
    margin: 0 auto;
}

.book-demo-form {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (max-width: 550px) {
    .book-demo-page {
        margin-top: 50px;
    }

    .book-demo-container {
        width: 100%;
        max-width: 100%;
    }

    .book-demo-form {
        padding: 40px 20px;
    }

    .book-demo-container .MuiPaper-root {
        box-shadow: none;
    }
}