.l-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 171, 85, 0.08);
}

.login-wrap {
    max-width: 448px;
    width: 448px;
    margin: 0 auto;
}

.login-content {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.l-heading {
    font-size: 18px;
    font-weight: 500;
}

.l-policy-section {
    color: #4d4d4d;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.l-error {
    border: 1px solid #e50b20;
    font-size: 16px;
    line-height: 24px;
    padding: 11px 16px;
    border-radius: 4px;
    background-color: #e50b201a;
}

.verify-help-text {
    display: flex;
    flex-direction: row;
    padding-bottom: 12px;
    padding-top: 12px;
    justify-content: space-between;
}

.f-14 {
    font-size: 14px;
}

.otp-input-style {
    height: 50px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    color: #1a181e;
    width: 50px !important;
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    margin-right: 10px;
    padding: 11px 8px;
}

.otp-focus-style {
    border-color: #146eb4 !important;
}

@media (max-width: 550px) {
    .login-wrap {
        max-width: 100%;
        width: 100%;
    }

    .login-content {
        padding: 25px;
    }

    .l-page {
        background-color: transparent;
    }

    .l-page .MuiPaper-root {
        box-shadow: none;
    }
}

@media (max-width: 400px) {
    .otp-input-style {
        width: 45px !important;
        font-size: 30px;
    }
}

@media (max-width: 360px) {
    .otp-input-style {
        width: 40px !important;
        font-size: 28px;
    }
}

@media (max-width: 320px) {
    .otp-input-style {
        width: 35px !important;
        font-size: 25px;
    }
}